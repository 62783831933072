import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios'
import VueGtag from "vue-gtag-next"

const app = createApp(App)
  .use(router)
  .use(VueGtag, {property: {id: 'UA-207385902-1', router}})

app.mount('#app')
app.config.globalProperties.$api = axios