<template>
  <Header/>
  <div class="seller-port-container">
    <router-view />
  </div>
  <Footer/>
</template>

<script>
import Header from '@/components/atoms/Header.vue'
import Footer from '@/components/atoms/Footer.vue'

export default {
  name: 'App',
  components: {
    Header, Footer,
  },
  mounted() {
    const routeInstance = this.$route
    this.createTitleDesc(routeInstance)
    window.onload = () => {
      const header = document.querySelector(".b-header")
      if (header && header.clientWidth <= 750) {
        // スマホ番の一番のselect-reasonの動画非表示
        const firstReason = document.querySelectorAll('.select-reasons .reasons .reason')[0]
        firstReason?.classList.add("float-up")
      }
    }
    window.addEventListener("scroll", () => {
      // fadeIn
      document.querySelectorAll('.fade-in').forEach(element => {
        if (window.scrollY > element.offsetTop - window.innerHeight + 100) {
          element.classList.add("float-up")
        } else {
          element.classList.remove("float-up")
        }
      })
      document.querySelectorAll('.fade-in-element').forEach(element => {
        if (window.scrollY >= element.offsetTop - window.innerHeight + 100) {
          element.classList.add("float-up")
        } else {
          element.classList.remove("float-up")
        }
      })
            
      // if (location.pathname != '/') return
      const header = document.querySelector(".b-header")
      if (header && header.clientWidth > 750) {
        if (location.pathname == '/') {
          header.classList.toggle("bg-black", window.scrollY > 200 && window.scrollY < 630)
          header.classList.toggle("bg-white", window.scrollY >= 630)
        } else if(location.pathname == '/') {
          header.classList.toggle("bg-black", window.scrollY > 50)
          // header.classList.toggle("bg-black", window.scrollY > 50 && window.scrollY < 300)
          // header.classList.toggle("bg-white", window.scrollY >= 300)        
        } else {
          header.classList.toggle("bg-black", window.scrollY < 500)
          header.classList.toggle("bg-white", window.scrollY >= 500)        
        }
      }
    });
  },
  methods: {
    createTitleDesc(routeInstance) {
      // タイトル設定
      if(routeInstance.meta.title){
        var setTitle = routeInstance.meta.title + ' | 中国輸入なら全般的にサポートする仕入れ代行 | BUYBUY';
        document.title = setTitle;
      } else {
        document.title = ' | 中国輸入なら全般的にサポートする仕入れ代行 | BUYBUY'
      }
      // メタタグdescription設定
      if(routeInstance.meta.desc){
        var setDesc = routeInstance.meta.desc + ' | BUYBUY';
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    }
  },
  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance);
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
.seller-port-container {
  margin-top: 60px;
  min-height: calc(90vh - 100px);
}
.align-center {
  text-align: center;
}
.pt-4 {
  padding-top: 40px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mt-8 {
  margin-top: 80px;
}
.btn-primary {
  background: #F2B504;
  // color: #FFFFFF;
  border: none;
  cursor: pointer;  
}
.btn-inquiry {
  background: #F2B504;
  color: #FFFFFF;
  border: none;
  cursor: pointer;        
  padding: 8px 30px;
  font-weight: 800;
  overflow: hidden;
  position: relative;
  height: 40px;
  border: 1px solid #F2B504;  
  &:hover {
    // color: #F2B504 !important;
    border: 1px solid white;
    background: #f2b704e5;
  }
}
@keyframes fadeIn {
  0% { 
    opacity: 0;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px); 
  }
  100% { 
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); 
  }
}
.float-up {
  animation: fadeIn 1s ease 0.2s both;
}
.fade-in, .fade-in-element {
  opacity: 0;
}
.fade-in-elements {
  .float-up:nth-child(2) {
    animation: fadeIn 1s ease 0.4s both;
  }
  .float-up:nth-child(3) {
    animation: fadeIn 1s ease 0.6s both;
  }
  .float-up:nth-child(4) {
    animation: fadeIn 1s ease 0.7s both;
  }
  .float-up:nth-child(5) {
    animation: fadeIn 1s ease 0.9s both;
  }  
}
@media (min-width: 751px) {
  .sp {
    display: none;
  }
  .service-top {
    background-size: cover !important;
    background-position-y: center !important;
    .content {
      background: rgba(51,51,51,0.78);
      height: 100%;
      padding-left: 8%;
      color: white;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      h1 {
        border-bottom: 1px solid white;
        margin-bottom: 0;
        padding-bottom: 10px;
      }
      p {
        margin-bottom: 50px;
      }
    }    
    h1 {
      width: 30%;
    }
  }  
}
@media screen and (max-width: 750px) {
  .pc {
    display: none !important;
  }
  .service-top {
    .content {
      padding: 0 8%;
    }
    h1 {
      width: 100%;
    }
  }  
}
</style>
