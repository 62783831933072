
import { createRouter, createWebHistory } from 'vue-router'

const top = () => import('./pages/Top.vue')
const serviceInspection = () => import('./pages/ServiceInspection.vue')
const serviceSupport = () => import('./pages/ServiceSupport.vue')
const ServiceLogistics = () => import('./pages/ServiceLogistics.vue')
const ServiceOem = () => import('./pages/ServiceOem.vue')
const news = () => import('./pages/News.vue')
const newsDetail = () => import('./pages/NewsDetail.vue')
const contact = () => import('./pages/Contact.vue')
const company = () => import('./pages/Company.vue')
const privacy = () => import('./pages/Privacy.vue')
const terms = () => import('./pages/Terms.vue')
const plan = () => import('./pages/Plan.vue')

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: top,
      meta: {
        title: '中国輸入なら全般的にサポートする仕入れ代行',
        desc: '面倒だった中国からの仕入れをもっと簡単に！仕入から物流までの悩みを解決する輸入代行のSellerPort',
      }
    },
    {
      path: '/service',
      name: 'serviceSupport',
      component: serviceSupport,
      meta: { title: '仕入れサービス', desc: '中国仕入れ代行 SellerPort 仕入れサービス' }
    },
    {
      path: '/service/inspection',
      name: 'serviceInspection',
      component: serviceInspection,
      meta: { title: '検品サービス', desc: '中国仕入れ代行 SellerPort 検品サービス' }
    },
    {
      path: '/service/logistics',
      name: 'ServiceLogistics',
      component: ServiceLogistics,
      meta: { title: '国際郵送サービス', desc: '中国仕入れ代行 SellerPort 国際郵送サービス' }
    },
    {
      path: '/service/oem',
      name: 'ServiceOem',
      component: ServiceOem,
      meta: { title: 'OEMサービス', desc: '中国仕入れ代行 SellerPort OEMサービス' }
    },          
    {
      path: '/news',
      name: 'news',
      component: news,
      meta: { title: 'お知らせ', desc: '中国仕入れ代行 SellerPort お知らせ' }
    },
    {
      path: '/news/:id',
      name: 'newsDetail',
      component: newsDetail,
      meta: { title: 'お知らせ', desc: '中国仕入れ代行 SellerPort お知らせ' }
    },
    {
      path: '/contact',
      name: 'contact',
      component: contact,
      meta: {
        title: 'お問い合わせ',
        desc: '仕入輸入販売のお問い合わせフォーム　中国仕入れ代行 SellerPort',
      }
    },
    {
      path: '/company',
      name: 'company',
      component: company,
      meta: {
        title: '会社概要',
        desc: '仕入輸入販売のSellerPort 株式会社アスラク 会社情報',
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: privacy,
      meta: {
        title: 'プライバシポリシー',
        desc: '仕入輸入販売のSellerPort プライバシポリシー',
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: terms,
      meta: {
        title: '利用規約',
        desc: '仕入輸入販売のSellerPort 利用規約',
      }
    },
    {
      path: '/plan',
      name: 'plan',
      component: plan,
      meta: {
        title: '料金プラン',
        desc: '仕入輸入販売のSellerPort 料金プラン',
      }
    },
  ],
  scrollBehavior() {
    return {top: 0}
  }  
})
export default router
