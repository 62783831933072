<template>
  <div class="b-header bg-black">
    <div class="logo-box">
      <img src="@/assets/logo-white.png" class="logo logo-white" />
      <img src="@/assets/logo-orange.png" class="logo logo-orange" />
      <img
        alt="SllerPort logo text"
        class="logo-text logo-white"
        @click="toTop()" src="@/assets/seller-port-white.svg" />
      <img
        alt="SllerPort logo text"
        class="logo-text logo-orange"
        @click="toTop()" src="@/assets/seller-port-orange.svg" />        
    </div>
    <div>
      <ul class="menu-pc">
        <button @click="toInquiry" class="btn-inquiry">お問い合せ</button>
        <li><a :class="{active: isSelectedPrivacy}" href="/privacy">プライバシーポリシー</a></li>
        <li><a :class="{active: isSelectedNews}" href="/news">ニュース一覧</a></li>
        <li><a :class="{active: isSelectedService}" href="/service">サービス</a></li>
        <li><a :class="{active: isSelectedTop}" href="/">ホーム</a></li>
      </ul>
      <span class="menu">
        <img 
          v-if="!menuDisplay"
          @click="showMenu()"
          class="menu-icon" 
          src="@/assets/icon-lines.svg"/>
        <span
          v-else
          @click="showMenu()"
          class="times">
          <span
            class="line line01"></span>
          <span
            class="line line02"></span>
        </span>
      </span>
    </div>
  </div>
  <div
    :class="{unactive: !menuDisplay}"
    @click="clicked()"
    class="menu-sp sp">
    <ul>
      <a
        v-for="(menu, index) in menus"
        :key="index"
        :href="menu.path">
        <li>
          {{menu.text}}
        </li>
      </a>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  },
  computed: {
    reversedMenus() {
      return [...this.menus].reverse()
    },
    isSelectedTop() {
      console.log('path:' + this.$route.path)
      return this.$route.path === '/'
    },
    isSelectedService() {
      return this.$route.path.includes('/service')
    },
    isSelectedNews() {
      return this.$route.path.includes('/news')
    },
    isSelectedPrivacy() {
      return this.$route.path.includes('/privacy')
    },    
  },
  mounted() {
    if (location.pathname == '/' && document.querySelector(".b-header").clientWidth > 750) {
      document.querySelector(".b-header").classList.remove('bg-black')
    }
  },
  data() {
    return {
      menuDisplay: false,
      menus: [
        {text: 'ホーム', path: '/'},
        {text: 'サービス', path: '/service'},
        {text: 'ニュース一覧', path: '/news'},
        {text: 'プライバシーポリシー', path: '/privacy'},
      ]
    }
  },
  methods: {
    toTop() {
      location.href = '/'
    },
    showMenu() {
      this.menuDisplay = !this.menuDisplay
    },
    toInquiry() {
      location.href = '/contact'
    },
  }
}
</script>

<style lang="scss" scoped>
.b-header {
  z-index: 999;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  a {
    color: white;
  }
  .logo-orange {
    display: none;
  }
}
.bg-black {
  background: rgba(51,51,51,0.78);
  // box-shadow: 0 -8px 8px 8px rgb(0 0 0 / 50%);
  .logo-orange {
    display: none;
  }
}
.bg-white {
  background: rgba(255,255,255,0.9);
  box-shadow: 0 -8px 8px 8px #f7ebeb;
  color: black;
  a {
    color: black;
  }
  .menu-pc {
    a:hover {
      color: #F2B504;
    }
  }
  .logo-orange {
    display: block;
  }
  .logo-white {
    display: none;
  }
}
.logo-box {
  display: flex;
  align-items: center;
  padding-left: 50px;
  .logo {
    height: 18px;
    margin-left: 12px;
  }
  .logo-text {
    height: 20px;
    width: 108px;
    margin-left: 15px;
    cursor: pointer;
  }
}
.bg-sp {
  background: rgba(51,51,51,0.78);  
}

.menu {
  display: inline-flex;
  justify-content: flex-end;
  flex: 1;
  .menu-icon {
    width: 30px;
    margin-right: 15px;
  }
  .times {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    padding-top: 5px;
    .line01 {
      transform: translateY(10px) rotate(-45deg);
      transition: 0.4s ease;
      background: white;
      height: 5px;
      width: 30px;
      display: inline-flex;
      position: absolute;
    }
    .line02 {
      transition: 0.4s ease;
      transform: translateY(10px) rotate(45deg);
      background: white;
      height: 5px;
      width: 30px;
      display: inline-flex;
      position: absolute;
    }
  }
}
.unactive {
  opacity: 0;
  transform: translateX(-100%);
}

a {
  text-decoration: none;
}

@media (min-width: 751px) {
  .menu, .menu-sp {
    display: none;
  }
  .menu-pc {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: baseline;
    flex-direction: row-reverse;
    margin-right: 30px;
    font-size: 16px;
    min-width: 700px;
    li {
      list-style: none;
      cursor: pointer;
      padding: 0 1rem;
      display: flex;      
    }
    a {
      margin-top: 5px;
      padding: 0px 5px 4px 5px;
    }
    a:hover, .active {
      border-bottom: 3px solid #F2B504;      
    }
    .btn-inquiry {
      margin-left: 20px;
    }
  }
}

@media screen and (max-width: 750px) {
  .b-header {
    .logo-box {
      padding-left: 5px;
    }
  }
  .menu-pc {
    display: none;
  }
  .menu-sp {
    position: fixed;
    z-index: 1000;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.4s;
    background: #3b4552;
    padding: 1rem;
    display: flex;

    ul {
      list-style: none;
      padding-inline-start: 10px;
      width: 90%;
      li {
        width: 100%;
        border-bottom: 1px solid white;
        padding: 10px 5px;
        margin-bottom: 15px;
        color: white;
      }
    }
  }
}
</style>
