<template>
  <div class="b-footer">
    <div class="footer-content">
      <div class="logo">
        <img src="@/assets/footer-logo.svg"/>
        <p class="pc">© 2022 ASURAKU CO., LTD.</p>
      </div>
      <div class="links">
        <div class="company pc">
          <div>運営会社&nbsp;&nbsp;(<a href="/company">株式会社アスラク</a>)</div>
          <p>
            <img src="@/assets/icon-twitter.svg"/>
            <img src="@/assets/icon-facebook.svg"/>            
          </p>
        </div>
        <div class="service">
          <li><a href="/service">仕入れ代行サービス</a></li>
          <li><a href="/service/inspection">検品サービス</a></li>
          <li><a href="/service/logistics">国際郵送サービス</a></li>
          <li><a href="/service/oem">OEMサービス</a></li>
          <li><a href="/news">ニュース</a></li>  
        </div>
        <div class="form">
          <li><a href="/contact">お問い合わせ</a></li>
          <li><a href="/privacy">プライバシーポリシー</a></li>
          <div class="company sp">
            <div>運営会社(<a href="/company">株式会社アスラク</a>)</div>
            <p>
              <img src="@/assets/icon-twitter.svg"/>
              <img src="@/assets/icon-facebook.svg"/>            
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String,
  },
  data() {
    return {
      links: [
        {name: '利用規約', path: '/terms'},
        {name: 'プライバシーポリシー', path: '/privacy'},
        {name: '会社概要', path: '/company'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.b-footer {
  font-size: 14px;
  background: url('../../assets/footer.png');
  height: 242px;
  color: white;
  background-size: cover;
  background-position-y: top;
  .footer-content {
    background: rgba(51,51,51,0.8);
    height: 100%;
    display: flex;
    .logo {
      flex: 2;
      flex-direction: column;
      display: flex;
      align-content: flex-start;
      justify-content: space-between;
    }
    .links {
      flex: 3;
      display: flex;
      padding: 0 5%;
      padding-top: 30px;
      a {
        text-decoration: none;
        color: white;
      }
      .company {
        flex: 1.5;
        text-align: center;
        p {
          display: flex;
          justify-content: space-evenly;
          padding: 0 30%;
          img {
            width: 23px;
          }
        }
      }
      .service {
        flex: 1;
        list-style: none;
        text-indent: 20px;
        li {
          padding-bottom: 5px;
          cursor: pointer;
        }
      }
      .form {
        flex: 1;
        list-style: none;
        text-indent: 20px;
      }
    }
  }
  .links {
    width: 80%;
    margin: auto;
  }
  ul {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding-inline-start: 0px;
    margin-bottom: 20px;
    li {
      padding: 3px 0px;
      a {
        text-decoration: none;
      }
    }
  }
}

@media (min-width: 751px) {
  .footer-content {
    .logo {
      padding-top: 55px;
      align-items: center;
      img {
        width: 220px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .b-footer {
    background-size: cover;
    background-position: top;
    .footer-content {
      flex-direction: column;
      .logo {
        padding-top: 25px;
        align-items: flex-start;
        padding-left: 30px;
        margin-bottom: 30px;
        img {
          width: 180px;
        }
      }
      .links {
        margin-bottom: 50px;
        width: 85%;
        padding: 0;
        .service {
          text-indent: 0;
          flex: 2;
        }
        .form {
          flex: 3;
          align-content: space-between;
          flex-direction: column;
          li {
            flex: 1;
            padding-bottom: 5px;
          }
        } 
        .company {
          text-indent: 12px;
          flex: 1;
          margin-top: 15px;
          p {
            padding: 0 20%;
          }
        }
      }
    }    
  }
}
</style>
